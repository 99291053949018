
import {defineComponent, onMounted} from "vue";
import EditCustomer from "../components/EditCustomer.vue";
import {setPageHeader} from "@/core/helpers/toolbar";
export default defineComponent({
    components: {
        EditCustomer
    },
    setup() {
        onMounted(() => {
            setPageHeader({
                title: "message.CUSTOMERS",
                actionButton : null,
                breadCrumbs: [
                    { name: "message.HOME", link: "/dashboard"},
                    { name: "message.CUSTOMERS", link: "#"},
                    { name: "message.CUSTOMERS", link: "/customer/lists"},
                    { name: "message.EDIT message.CUSTOMER"}
                ]
            });
        })
    }
});
